<template>
  <div class="container-fluid">
    <template>
      <div class="container">
        <h2 class="text-center">Servicios</h2>
        <div class="row">
          <div class="col-12 py-3">
            <section>
              <div class="container">
                <div class="col-12"></div>
                <div
                  class="row justify-content-center mb-3"
                  v-for="item in productList"
                  :key="item.id"
                >
                  <div class="col-md-12 col-xl-10">
                    <div class="card shadow-0 border rounded-3">
                      <div class="card-body">
                        <div class="row">
                          <div class="col-md-12 col-lg-3 col-xl-3 mb-4 mb-lg-0">
                            <div class="bg-image hover-zoom ripple rounded ripple-surface">
                              <img :src="item.img_url" class="w-100" />
                              <a href="#!">
                                <div class="hover-overlay">
                                  <div
                                    class="mask"
                                    style="background-color: rgba(253, 253, 253, 0.15)"
                                  ></div>
                                </div>
                              </a>
                            </div>
                          </div>
                          <div class="col-md-6 col-lg-6 col-xl-6">
                            <h5>{{ item.name }}</h5>
                            <div class="d-flex flex-row">
                              <div class="text-info mb-1 me-2">
                                <h5>{{ item.service.name }}</h5>
                              </div>
                            </div>
                            <div class="mt-1 mb-0 text-muted small">
                              <span>100% seguridad en nuestro trabajo</span>
                              <span class="text-primary"> • </span>
                              <span>Los mas altos estandares de calidad</span>
                              <span class="text-primary"> • </span>
                              <span>Tratada por expertos<br /></span>
                            </div>
                            <div class="mb-2 text-muted small">
                              <span class="text-primary"> • </span>
                              <span>Con los mejores tiempos</span>
                            </div>
                            <p class="text-truncate mb-4 mb-md-0">
                              {{ item.description }}
                            </p>
                          </div>
                          <div class="col-md-6 col-lg-3 col-xl-3 border-sm-start-none border-start">
                            <div class="d-flex flex-row align-items-center mb-1">
                              <!-- h4 class="mb-1 me-1">$ {{ item.unit_price_normal }}</h4-->
                              <h6 class="d-flex justify-content-between mb-2 w-100">
                                <span class="text-primary">Precio Regular: </span>
                                <span class="text-success">${{ item.unit_price_normal }}</span>
                              </h6>
                            </div>
                            <div class="d-flex flex-row align-items-center mb-1">
                              <h6 class="d-flex justify-content-between mb-2 w-100">
                                <span class="text-primary">Precio Urgente: </span>
                                <span class="text-secondary"
                                  >${{ Math.round(item.unit_price_normal * 1.3).toFixed(2) }}</span
                                >
                              </h6>
                            </div>
                            <div class="d-flex flex-column mt-4">
                              <button
                                class="btn btn-outline-primary btn-sm mt-2"
                                type="button"
                                @click="addProductoCar(item)"
                              >
                                Agregar al carrito
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <paginate-links
              for="products"
              :async="true"
              :classes="{ ul: 'pagination', li: 'page-item', a: 'page-link' }"
            ></paginate-links>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import moment from 'moment';
// API
import { mapState, mapActions } from 'vuex';

export default {
  name: 'ProductList',

  data: () => ({
    titlepag: 'Productos',
    discount_alert: {
      message: null,
      type: 'red--text',
    },
    discount_code: null,
    discount: null,
    currentDate: moment().format('YYYY-MM-DD'),
    offices: [],
    paginate: ['servicios'],
    services: [],
    serviceId: null,
    officeId: null,
    productList: [],
    selectServices: [],
    cart: [],
    total: 0,
  }),

  mounted() {
    this.initFunction();
  },
  methods: {
    ...mapActions('signup', ['addProductoCar']),
    // agregar productos a state
    async initFunction() {
      await this.getProductState();
      this.productList = this.products
        .map((item) => {
          const currentService = JSON.parse(JSON.stringify(item));
          currentService.service.name = currentService.service.name.replace('.', '');
          return currentService;
        })
        .sort((a, b) => {
          const nameA = a.name.toLowerCase();
          const nameB = b.name.toLowerCase();
          if (nameA === nameB) {
            return 0;
          }
          return nameA < nameB ? -1 : 1;
        });
    },
    async getProductState() {
      // this.$store.commit('showLoader');
      try {
        await this.$store.dispatch('signup/getAllProducts');
      } catch (error) {
        console.log(error);
        this.$store.dispatch('notification/notifyError');
      }
      // this.$store.commit('hideLoader');
    },
  },
  watch: {},
  computed: {
    ...mapState('signup', ['products']),
  },
};
</script>

<style lang="scss" scoped></style>
