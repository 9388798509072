<template>
  <div>
    <section class="section-navbar sticky-top">
      <Navbar />
    </section>
    <div class="bodyproductos">
      <div class="container">
        <header>
          <h1 class="titulo-productos text-center">Servicios Wapidu</h1>
        </header>
        <div class="row">
          <div class="col-12">
            <ProductList />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import componentes
import ProductList from '@/components/tienda/productList/index.vue';
import Navbar from '@/components/navbar/index.vue';
/* *************************************
VUE INSTANCE
************************************* */
/* eslint-disable no-plusplus */
export default {
  name: 'Productos',
  data: () => ({
    appTitle: '',
  }),
  created() {},
  components: {
    ProductList,
    Navbar,
  },
  computed: {},
};
</script>
<style lang="" scoped></style>
